import React, { useMemo } from 'react'
import { RepairOrderStatus } from '../../../api/adapters/repairOrder/types'
import { UserScope } from '../../constants/user'
import { ServiceDetailsInfoComponent } from './components/ServiceDetailsInfo'
import { RepairOrderInfoComponent } from './components/RepairOrderInfo'
import { CreateServiceDetailsComponent } from './components/CreateServiceDetails'
import { ScrollView } from 'react-native'
import { styles } from './styles'
import { useIsEditableByCompany } from '../../../businessLogic/repairOrder'
import type { RepairOrderProps } from './types'

export function RepairOrderComponent({
  scope,
  repairOrder,
  onStatusUpdate,
  onServiceDetailsUpdate,
  onServiceDetailStatusUpdate,
}: RepairOrderProps) {
  const isEditableByCompany = useIsEditableByCompany(scope, repairOrder)
  const hasServiceDetails = useMemo(
    () =>
      Array.isArray(repairOrder.serviceDetails) &&
      repairOrder.serviceDetails.length > 0,
    []
  )

  switch (scope) {
    case UserScope.Customer:
      return (
        <ScrollView contentContainerStyle={styles.container}>
          <RepairOrderInfoComponent
            repairOrder={repairOrder!}
            action={{
              isEnabled:
                repairOrder.status ===
                RepairOrderStatus.WaitingCustomerApproval,
              onPress: (repairOrderId: string) =>
                onStatusUpdate(repairOrderId, RepairOrderStatus.Progress),
              label: 'Approve',
            }}
          ></RepairOrderInfoComponent>

          {hasServiceDetails && (
            <ServiceDetailsInfoComponent
              serviceDetails={repairOrder.serviceDetails!}
            ></ServiceDetailsInfoComponent>
          )}
        </ScrollView>
      )
    case UserScope.CompanyOwner:
      return (
        <ScrollView contentContainerStyle={styles.container}>
          <RepairOrderInfoComponent
            repairOrder={repairOrder!}
            action={{
              isEnabled:
                hasServiceDetails &&
                (repairOrder.status === RepairOrderStatus.Pending ||
                  repairOrder.status === RepairOrderStatus.PaymentPending),
              onPress: (repairOrderId: string) =>
                onStatusUpdate(
                  repairOrderId,
                  repairOrder.status === RepairOrderStatus.Pending
                    ? RepairOrderStatus.WaitingCustomerApproval
                    : RepairOrderStatus.Archived
                ),
              label:
                repairOrder.status === RepairOrderStatus.Pending
                  ? 'Submit'
                  : 'Finalize',
            }}
          ></RepairOrderInfoComponent>

          {isEditableByCompany ? (
            <CreateServiceDetailsComponent
              companyId={repairOrder.company.id}
              serviceDetails={repairOrder.serviceDetails}
              onSave={onServiceDetailsUpdate}
            ></CreateServiceDetailsComponent>
          ) : (
            <ServiceDetailsInfoComponent
              serviceDetails={repairOrder.serviceDetails!}
            ></ServiceDetailsInfoComponent>
          )}
        </ScrollView>
      )
    case UserScope.Employee:
      return (
        <ScrollView contentContainerStyle={styles.container}>
          <RepairOrderInfoComponent
            repairOrder={repairOrder!}
          ></RepairOrderInfoComponent>

          <ServiceDetailsInfoComponent
            serviceDetails={repairOrder.serviceDetails!}
            showMechanicInfo={false}
            action={{
              isEnabled: true,
              onPress: (serviceDetailId, newStatus) =>
                onServiceDetailStatusUpdate({
                  repairOrderId: repairOrder.id,
                  serviceDetailId: serviceDetailId,
                  status: newStatus,
                }),
            }}
          ></ServiceDetailsInfoComponent>
        </ScrollView>
      )
  }
}
