import type { UserScope } from '../common/constants/user'

export enum VerificationType {
  Email = 'email',
  Phone = 'phone',
}
export enum AppRouts {
  // Public Routes
  Home = 'Home',
  Register = 'Register',
  AccountActivation = 'AccountActivation',
  Login = 'Login',
  NotFound = 'NotFound',

  // Private Routes
  Drawer = 'Drawer',
  CreateProfile = 'CreateProfile',

  // Main
  CustomerHome = 'CustomerHome',
  CustomerProfile = 'CustomerProfile',
  CustomerVehicles = 'CustomerVehicles',
  CustomerVehicleDetails = 'CustomerVehicleDetails',
  CustomerOpenServiceRequests = 'CustomerOpenServiceRequests',
  CustomerServiceHistory = 'CustomerServiceHistory',
  Settings = 'Settings',

  // Common
  RepairOrder = 'RepairOrder',

  // Company
  CompanyProfile = 'CompanyProfile',
  CompanyEmployees = 'CompanyEmployees',
  CompanyServicedVehicles = 'CompanyServicedVehicles',

  // Employee
  EmployeeProfile = 'EmployeeProfile',
  EmployeeServicedVehicles = 'EmployeeServicedVehicles',

  // Admin
  VehicleCatalog = 'VehicleCatalog',
}

export type AccountActivationStackParam = { userId: string }
export type CustomerVehicleDetailsStackParam = { vehicleId?: string }
export type RepairOrderStackParam = {
  backButtonScreen: AppRouts
  /**
    PageScope specifies the user context in which the page should be used.
    This is helpful when the repairOrder component is accessed from different contexts,
    such as loading the page from both user and company perspectives.
   */
  pageScope?: UserScope
  /**
   * If not specified it's a 'Create' mode.
   */
  repairOrderId?: string
}

export type RootStackParamList = {
  [AppRouts.Home]: undefined
  [AppRouts.Register]: undefined
  [AppRouts.AccountActivation]: AccountActivationStackParam
  [AppRouts.Login]: undefined
  [AppRouts.NotFound]: undefined

  [AppRouts.Drawer]: undefined

  [AppRouts.CreateProfile]: undefined

  [AppRouts.CustomerHome]: undefined
  [AppRouts.CustomerProfile]: undefined
  [AppRouts.CustomerVehicles]: undefined
  [AppRouts.CustomerVehicleDetails]: CustomerVehicleDetailsStackParam
  [AppRouts.CustomerOpenServiceRequests]: undefined
  [AppRouts.CustomerServiceHistory]: undefined
  [AppRouts.Settings]: undefined

  [AppRouts.RepairOrder]: RepairOrderStackParam

  [AppRouts.CompanyProfile]: undefined
  [AppRouts.CompanyEmployees]: undefined
  [AppRouts.CompanyServicedVehicles]: undefined

  [AppRouts.EmployeeProfile]: undefined
  [AppRouts.EmployeeServicedVehicles]: undefined

  [AppRouts.VehicleCatalog]: undefined
  // ... Add more routes as needed
}
