import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'

import { useAuthenticatedUser } from '../../../../contexts/AuthContext'
import { styles } from './styles'
import { Profile } from '../../../../api/adapters/fetchCurrentUser'
import { useNavigationService } from '../../../../navigation/Service'
import { AppRouts } from '../../../../navigation/types'
import { UserScope } from '../../../../common/constants/user'

export function CustomerHomePageScreen() {
  const user = useAuthenticatedUser()
  const navigation = useNavigationService()
  const [profile, setProfile] = useState<Profile>()

  useEffect(() => {
    setProfile(user.profile)
  }, [user])

  if (!profile) {
    return (
      <View style={styles.centered}>
        <Text style={styles.noProfileText}>No Profile Available</Text>
      </View>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Header Section */}
      <View style={styles.welcomeTextContainer}>
        <Text style={styles.welcomeText}>Welcome,</Text>
        <Text style={styles.userDataText}>{profile.firstName}!</Text>
      </View>
      <View style={styles.goToProfileButtonContainer}>
        <TouchableOpacity
          style={styles.button}
          onPress={navigation.navigateCustomerProfile}
        >
          <Text style={styles.buttonText}>Go To Profile</Text>
        </TouchableOpacity>
      </View>

      {/* Service Requests Section */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Service Requests</Text>
        {user.currentScope === UserScope.Customer && (
          <TouchableOpacity
            style={styles.linkButton}
            onPress={() =>
              navigation.navigateRepairOrder({
                backButtonScreen: AppRouts.CustomerHome,
                pageScope: user.currentScope,
              })
            }
          >
            <Text style={styles.linkText}>New Service Request</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={styles.linkButton}
          onPress={navigation.navigateCustomerOpenServiceRequests}
        >
          <Text style={styles.linkText}>Active Service Requests</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.linkButton}
          onPress={navigation.navigateCustomerServiceHistory}
        >
          <Text style={styles.linkText}>Service History</Text>
        </TouchableOpacity>
      </View>

      {/* Vehicles Section */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>My Vehicles</Text>
        <TouchableOpacity
          style={styles.linkButton}
          onPress={() => navigation.navigateCustomerVehicles()}
        >
          <Text style={styles.linkText}>Manage Vehicles</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  )
}
