import React from 'react'
import { View, Text, ScrollView, Pressable } from 'react-native'
import { styles } from './styles'
import { RenderRepairOrderItem } from './components/RenderRepairOrderItem'
import type { RepairOrderListProps } from './types'

export function RepairOrderListComponent({
  scope,
  repairOrders,
  actions: { create, view, remove },
}: RepairOrderListProps) {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {create?.isEnabled && (
          <Pressable style={styles.createButton} onPress={create.onPress}>
            <Text style={styles.createButtonText}>New Service Request</Text>
          </Pressable>
        )}
      </View>
      <ScrollView
        contentContainerStyle={styles.listContainer}
        scrollEnabled={true}
      >
        {repairOrders.length ? (
          repairOrders.map((item) => (
            <RenderRepairOrderItem
              key={item.id}
              scope={scope}
              item={item}
              actions={{ remove, view }}
            />
          ))
        ) : (
          <View style={styles.container}>
            <View style={styles.centered}>
              <Text style={styles.noData}>No Open Services Available</Text>
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  )
}
