import { StyleSheet } from 'react-native'
import {
  COLOR_BUTTON_AQUA_BLUE_DARK,
  COLOR_BUTTON_AQUA_BLUE_LIGHT,
  COLOR_WHITE,
  COLOR_WHITE_DARK,
} from '../../../../common/constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: COLOR_WHITE,
  },
  welcomeTextContainer: {
    shadowColor: COLOR_WHITE_DARK,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingHorizontal: 24,
    paddingVertical: 22,
    backgroundColor: COLOR_BUTTON_AQUA_BLUE_DARK,
    flexDirection: 'column', // Change from 'row' to 'column'
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically
  },
  welcomeText: {
    fontSize: 24,
    fontWeight: '600',
    color: COLOR_WHITE,
  },
  userDataText: {
    paddingTop: 6,
    fontSize: 20,
    fontWeight: '500',
    color: COLOR_WHITE_DARK,
  },
  goToProfileButtonContainer: {
    justifyContent: 'center',
    marginTop: 1,
    marginBottom: 22,
  },
  button: {
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 20,
    backgroundColor: COLOR_BUTTON_AQUA_BLUE_LIGHT,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderColor: COLOR_BUTTON_AQUA_BLUE_DARK,
    borderWidth: 1,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  buttonText: {
    color: COLOR_WHITE,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },

  /* Section Styles */
  section: {
    marginBottom: 20,
    padding: 16,
    backgroundColor: COLOR_WHITE,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 3,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 12,
    color: COLOR_BUTTON_AQUA_BLUE_DARK,
  },
  linkButton: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    marginBottom: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLOR_BUTTON_AQUA_BLUE_DARK,
    backgroundColor: COLOR_WHITE_DARK,
  },
  linkText: {
    fontSize: 16,
    fontWeight: '500',
    color: COLOR_BUTTON_AQUA_BLUE_DARK,
    textAlign: 'center',
  },

  /* Centered Styles for Empty State */
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noProfileText: {
    fontSize: 18,
    color: '#888',
  },
})
