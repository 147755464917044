import { StyleSheet } from 'react-native'
import {
  COLOR_REPAIR_ORDER_STATUS,
  COLOR_BUTTON_AQUA_BLUE,
} from '../../../../constants/colors'

export const styles = StyleSheet.create({
  container: {
    //flex: 1,
    flexDirection: 'row',
    //flexWrap: 'wrap',
    //justifyContent: 'space-between', // Distributes cards evenly
    marginRight: 12,
    minWidth: 320,
  },
  card: {
    flexBasis: '30%', // 30% width for desktop
    //maxWidth: '30%', // Ensure cards don't exceed 30% width
    minWidth: '100%', // Full width for mobile
    marginBottom: 16,
    padding: 16,
    backgroundColor: '#FFF',
    borderRadius: 12,
    elevation: 3,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
  },
  statusContainer: {
    marginBottom: 8,
    paddingVertical: 4,
    paddingHorizontal: 8,
    alignSelf: 'flex-start',
    backgroundColor: COLOR_REPAIR_ORDER_STATUS,
    borderRadius: 4,
  },
  status: {
    fontSize: 14,
    color: '#FFF',
    fontWeight: 'bold',
  },
  content: {
    marginTop: 16,
  },
  vehicleInfo: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 4,
  },
  userInfo: {
    fontSize: 14,
    color: '#555',
    marginBottom: 4,
  },
  itemsInfo: {
    fontSize: 14,
    color: COLOR_BUTTON_AQUA_BLUE,
  },
})

// import { StyleSheet } from 'react-native'
// import { COLOR_REPAIR_ORDER_STATUS } from '../../../../constants/colors'

// // Styling for the component
// export const styles = StyleSheet.create({
//   section: {
//     marginBottom: 8,
//     padding: 10,
//     backgroundColor: '#FFF',
//     borderRadius: 8,
//     shadowColor: '#F8F9FA',
//     shadowOpacity: 0,
//     shadowRadius: 8,
//     elevation: 3,
//   },
//   row: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//   },
//   label: {
//     fontSize: 18,
//     color: '#888',
//     //width: 160,
//     marginHorizontal: 6,
//   },
//   statusLabel: {
//     fontSize: 18,
//     color: COLOR_REPAIR_ORDER_STATUS,
//     width: 80,
//     marginHorizontal: 6,
//   },
//   // costLabel: {
//   //   fontSize: 18,
//   //   color: '#888',
//   //   width: 30,
//   //   marginHorizontal: 6,
//   // },

//   actions: { flexDirection: 'row', gap: 16 },
// })
