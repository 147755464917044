import { ProfileSummary } from './../fetchCurrentUser'
import type { CompanySummary } from '../fetchCompanies'
import type { VehicleSummary } from '../vehicle/types'

export type RepairOrder = {
  id: string // Unique Id for the order
  customerId: string // Associated customer (userId)
  companyId: string // Associated company
  vehicleId: string // Vehicle being serviced
  invoiceId?: string // Associated invoice
  serviceDetails: ServiceDetail[] // Array of services performed
  status: RepairOrderStatus // Status of the repair order
  totalCost: number // Total cost of the repair
  createdAt: string // Date order was created in ISO string format
  updatedAt: string // Date order was last updated in ISO string format
}

export type RepairOrderAggregate = {
  id: string
  customer: ProfileSummary
  vehicle: VehicleSummary
  company: Pick<CompanySummary, 'id' | 'name'>
  serviceDetails?: ServiceDetailAggregate[]
  status: RepairOrderStatus
  totalCost: number
  createdAt: string
  updatedAt: string
}

// Enum for RepairOrderStatus
export enum RepairOrderStatus {
  Pending = 'Pending',
  WaitingCustomerApproval = 'WaitingCustomerApproval',
  Progress = 'Progress',
  PaymentPending = 'PaymentPending',
  PaymentFailed = 'PaymentFailed',
  Disputed = 'Disputed',
  Archived = 'Archived',
}
// Enum for ServiceDetailStatus
export enum ServiceDetailStatus {
  Pending = 'Pending',
  Progress = 'Progress',
  Completed = 'Completed',
}

export type ServiceDetail = {
  id: string
  mechanicId: string
  cost: string // Cost of the service
  status: ServiceDetailStatus
  description: string // Description of the service
  serviceName: string // Name of the service (e.g., Oil Change)
  startDate: string // Date of start of work in ISO string format
  completionDate: string // Date of work completion in ISO string format
  updatedAt: string // Date last updated in ISO string format
}

export type CreateServiceDetailPayload = Pick<
  ServiceDetail,
  'mechanicId' | 'cost' | 'description' | 'id'
>

export type ServiceDetailAggregate = {
  id: string
  mechanic: ProfileSummary
  cost: number // Cost of the service
  status: ServiceDetailStatus
  description: string // Description of the service
  serviceName: string // Name of the service (e.g., Oil Change)
  startDate: string // Date of start of work in ISO string format
  completionDate: string // Date of work completion in ISO string format
  updatedAt: string // Date last updated in ISO string format
}

export type CreateRepairOrderPayload = {
  customerId: string
  companyId: string
  vehicleId: string
  description: string
}

export type CreateRepairOrderResponse = {
  id: string
}

export type GetRepairOrdersQueryParams = {
  customerId?: string
  companyId?: string
  mechanicId?: string
  vehicleId?: string
  status?: RepairOrderStatus[]
  limit?: number // Optional limit, 1 to 100
}
