import React from 'react'
import {
  View,
  Text,
  Pressable,
  ScrollView,
  ImageBackground,
} from 'react-native'
import { useNavigationService } from '../../../navigation/Service'
import { styles } from './styles'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import carBackground from '../../../assets/main_page.jpg'

export function HomePageScreen() {
  const navigation = useNavigationService()

  const onLoginPress = () => {
    navigation.navigateLogin()
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.headerText}>Welcome to the Mechanic Store!</Text>
        <View style={styles.buttonContainer}>
          <Pressable style={styles.loginButton} onPress={onLoginPress}>
            <Text style={styles.buttonText}>Login</Text>
          </Pressable>

          <Pressable
            style={styles.registerButton}
            onPress={() => navigation.navigateRegister()}
          >
            <Text style={styles.buttonText}>Register</Text>
          </Pressable>
        </View>
      </View>

      {/* Main Content */}
      <View style={styles.mainContentContainer}>
        <ImageBackground
          source={carBackground}
          resizeMode='cover'
          style={styles.mainContent}
        >
          <View style={styles.textWrapper}>
            <Text style={styles.tagline}>
              We are here to connect the best mechanics with customers.
            </Text>
            <Text style={styles.mission}>
              Our mission is to revolutionize car repairs by making expert
              mechanics accessible to everyone, ensuring trust, quality, and
              convenience.
            </Text>
          </View>
        </ImageBackground>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text style={styles.footerText}>
          Email: info@usmechanicservices.com
        </Text>
        <Text style={styles.footerText}>Phone: +1 (415) 728-7537</Text>
      </View>
    </ScrollView>
  )
}
