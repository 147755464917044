const config = {
  // Dev
  // baseApiUrlMobile: 'http://192.168.1.11:3000/api/v1', // This is dynamic of your current wifi network
  // baseApiUrlWeb: 'http://127.0.0.1:3000/api/v1',

  // Prod
  baseApiUrlMobile: 'https://api.usmechanicservices.com/api/v1',
  baseApiUrlWeb: 'https://api.usmechanicservices.com/api/v1',
}

export default config
