import { StyleSheet } from 'react-native'
import {
  COLOR_BLUE_DARK,
  COLOR_BUTTON_AQUA_BLUE_DARK,
  COLOR_WHITE,
} from '../../../../common/constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
  },
  header: {
    marginVertical: 4,
    paddingTop: 4,
    alignItems: 'center',
  },
  addButton: {
    marginTop: 4,
    backgroundColor: COLOR_BUTTON_AQUA_BLUE_DARK,
    paddingVertical: 10,
    paddingHorizontal: 18,
    borderRadius: 8,
    borderColor: COLOR_BLUE_DARK,
    marginBottom: 8,
  },
  addButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },

  listContent: {
    paddingHorizontal: 10,
  },
  section: {
    marginBottom: 8,
    padding: 10,
    backgroundColor: COLOR_WHITE,
    borderRadius: 8,
    shadowColor: COLOR_BLUE_DARK,
    shadowOpacity: 0,
    shadowRadius: 8,
    elevation: 3,
  },
  vehicleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 18,
    color: '#888',
  },

  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    fontSize: 18,
    color: '#888',
    marginBottom: 16,
  },

  // container: { padding: 16 },
  actions: { flexDirection: 'row', gap: 16 },
})
