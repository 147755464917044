import { StyleSheet } from 'react-native'
import {
  COLOR_AQUA_BLUE_DARK,
  COLOR_AQUA_BLUE_LIGHT,
  COLOR_BLUE,
  COLOR_BUTTON_AQUA_BLUE,
  COLOR_WHITE,
} from '../../../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    backgroundColor: COLOR_WHITE,
    borderTopWidth: 1,
    borderTopColor: COLOR_AQUA_BLUE_DARK,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: COLOR_AQUA_BLUE_LIGHT,
    marginVertical: 4,
    paddingVertical: 6,
  },
  inputContainer: {
    marginHorizontal: 5,
  },
  actionsContainer: {
    flex: 1,
    justifyContent: 'center', // Center the content initially
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
  },
  addButton: {
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    paddingVertical: 10,
    borderRadius: 8,
    width: 180,
    alignItems: 'center',
  },
  submitButton: {
    backgroundColor: COLOR_BLUE,
    marginTop: 8,
    paddingVertical: 10,
    borderRadius: 8,
    width: 180,
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },
  deleteButton: {
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginLeft: 5,
  },
  centered: {
    paddingTop: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    fontSize: 18,
    color: COLOR_AQUA_BLUE_DARK,
    marginBottom: 16,
  },
})
