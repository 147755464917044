import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { styles } from './styles'
import { UserScope } from '../../../../constants/user'
import type { RepairOrderListItemProps } from '../../types'
// import { COLOR_BUTTON_AQUA_BLUE } from '../../../../constants/colors'

export function RenderRepairOrderItem({
  item,
  scope,
  actions: { remove, view },
}: RepairOrderListItemProps) {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.card}
        onPress={() => view.onPress(item)}
        activeOpacity={0.8}
      >
        {/* Delete Button */}
        {remove?.isEnabled(item) && (
          <TouchableOpacity
            style={styles.deleteButton}
            onPress={() => remove.onPress(item)}
          >
            <MaterialIcons name='delete' size={20} color='red' />
          </TouchableOpacity>
        )}

        {/* Status */}
        <View style={styles.statusContainer}>
          <Text style={styles.status}>{item.status}</Text>
        </View>

        {/* Main Content */}
        <View style={styles.content}>
          <Text style={styles.vehicleInfo}>
            {item.vehicle.model} - {item.vehicle.make} / {item.vehicle.year}
          </Text>
          <Text style={styles.userInfo}>
            {scope === UserScope.CompanyOwner
              ? item.customer?.firstName
              : item.company?.name}
          </Text>
          {item.totalCost > 0 && (
            <Text style={styles.itemsInfo}>
              Total cost: {item.totalCost} USD
            </Text>
          )}
        </View>
      </TouchableOpacity>
    </View>
  )
}
