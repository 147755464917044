import React, { useState } from 'react'
import { useAuthenticatedUser } from '../../../../contexts/AuthContext'
import {
  GetRepairOrdersQueryParams,
  RepairOrderAggregate,
  RepairOrderStatus,
} from '../../../../api/adapters/repairOrder/types'
import { AxiosError } from 'axios'
import { deleteRepairOrder } from '../../../../api/adapters/repairOrder/deleteRepairOrder'
import { showAlert, showAlertConfirm } from '../../../../common/utils/alert'
import { useNavigationService } from '../../../../navigation/Service'
import { useFocusEffect } from '@react-navigation/native'
import { fetchRepairOrders } from '../../../../api/adapters/repairOrder/fetchRepairOrders'
import { RepairOrderListComponent } from '../../../../common/components/RepairOrderList'
import { UserScope } from '../../../../common/constants/user'
import { AppRouts } from '../../../../navigation/types'
import { useIsRemovableRepairOrder } from '../../../../businessLogic/repairOrder'

/**
 * TODO
 * Separate [Company] and [Employee] specific ServiceRequests from [Customer] one.
    For now due to luck of requirements we have only one component that shows ServiceRequest for All.
    The check is done when loading orders with fetchRepairOrders.
    This should be updated Either to define:
     - A specific component per each userRole (ex. CompanyServiceRequestsScreen, EmployeeServiceRequestsScreen, ...)
     - Or to rename the current one as 'ServiceRequestsScreen' in case we are not going to have employee or company specific orders.
 */
export function CustomerOpenServiceRequestsScreen() {
  const [repairOrders, setRepairOrders] = useState<RepairOrderAggregate[]>([])
  const user = useAuthenticatedUser()
  const navigation = useNavigationService()

  useFocusEffect(
    React.useCallback(() => {
      loadRepairOrders()
    }, [])
  )

  const handleDelete = async ({ id }: RepairOrderAggregate) => {
    showAlertConfirm(
      'Delete Item',
      'Are you sure you want to delete this item?',
      async () => {
        // When confirmed
        try {
          await deleteRepairOrder(id)
          showAlert('Success', 'RepairOrder successfully Deleted!')
          loadRepairOrders()
        } catch (error) {
          const errorMessage = (
            (error as AxiosError).response!.data as { error: string }
          ).error
          console.error(error)
          showAlert('Error', errorMessage)
        }
      }
    )
  }

  const handleViewDetails = async ({ id }: RepairOrderAggregate) => {
    navigation.navigateRepairOrder({
      repairOrderId: id,
      backButtonScreen: AppRouts.CustomerOpenServiceRequests,
      pageScope: user.currentScope,
    })
  }

  const handleCreateRepairOrder = async () => {
    navigation.navigateRepairOrder({
      backButtonScreen: AppRouts.CustomerOpenServiceRequests,
      pageScope: user.currentScope,
    })
  }

  const loadRepairOrders = async () => {
    const fetchRepairOrderParams: GetRepairOrdersQueryParams = {}
    // Since currently we only use one component to load repairOrders thus have to filter based on user scope
    if (user?.currentScope === UserScope.Employee) {
      Object.assign(fetchRepairOrderParams, {
        mechanicId: user.userId,
        companyId: user.profile!.employeeProfile!.companyId,
      })
    } else if (user?.currentScope === UserScope.CompanyOwner) {
      fetchRepairOrderParams.companyId =
        user.profile!.companyProfile!.companyId!
    } else {
      fetchRepairOrderParams.customerId = user.userId
    }

    // Only fetch Archived repairOrders
    fetchRepairOrderParams.status = [
      RepairOrderStatus.Pending,
      RepairOrderStatus.WaitingCustomerApproval,
      RepairOrderStatus.Progress,
      RepairOrderStatus.PaymentPending,
      // RepairOrderStatus.PaymentFailed,
      // RepairOrderStatus.Disputed,
    ]

    try {
      const data = await fetchRepairOrders(fetchRepairOrderParams)
      setRepairOrders(data)
    } catch (err) {
      // TODO handle properly
      console.error('Something went wrong when fetching repairOrders data', err)
    }
  }

  return (
    <RepairOrderListComponent
      scope={user.currentScope!}
      repairOrders={repairOrders}
      actions={{
        view: {
          isEnabled: true,
          onPress: handleViewDetails,
        },
        create: {
          isEnabled: user.currentScope === UserScope.Customer,
          onPress: handleCreateRepairOrder,
        },
        remove: {
          isEnabled: (item) =>
            useIsRemovableRepairOrder(user.currentScope!, item),
          onPress: handleDelete,
        },
      }}
    ></RepairOrderListComponent>
  )
}
