import { StyleSheet } from 'react-native'
import { COLOR_BUTTON_AQUA_BLUE } from '../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    flexGrow: 1,
    flex: 1,
  },
  header: {
    paddingVertical: 22,
    alignItems: 'center',
  },
  createButton: {
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    paddingVertical: 8,
    paddingHorizontal: 18,
    borderRadius: 8,
  },
  createButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },

  listContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    fontSize: 18,
    color: '#888',
    marginBottom: 16,
  },
})
