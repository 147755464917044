import React, { useState } from 'react'
import {
  RepairOrderStatus,
  type GetRepairOrdersQueryParams,
  type RepairOrderAggregate,
} from '../../../../api/adapters/repairOrder/types'
import { useAuthenticatedUser } from '../../../../contexts/AuthContext'
import { useNavigationService } from '../../../../navigation/Service'
import { useFocusEffect } from '@react-navigation/native'
import { AppRouts } from '../../../../navigation/types'
import { fetchRepairOrders } from '../../../../api/adapters/repairOrder/fetchRepairOrders'
import { UserScope } from '../../../../common/constants/user'
import { RepairOrderListComponent } from '../../../../common/components/RepairOrderList'

export function CustomerServiceHistoryScreen() {
  const [repairOrders, setRepairOrders] = useState<RepairOrderAggregate[]>([])
  const user = useAuthenticatedUser()
  const navigation = useNavigationService()

  useFocusEffect(
    React.useCallback(() => {
      loadRepairOrders()
    }, [])
  )

  const handleViewDetails = async ({ id }: RepairOrderAggregate) => {
    navigation.navigateRepairOrder({
      repairOrderId: id,
      backButtonScreen: AppRouts.CustomerOpenServiceRequests,
      pageScope: user.currentScope,
    })
  }

  const loadRepairOrders = async () => {
    const fetchRepairOrderParams: GetRepairOrdersQueryParams = {}
    // Since currently we only use one component to load repairOrders thus have to filter based on user scope
    if (user?.currentScope === UserScope.Employee) {
      Object.assign(fetchRepairOrderParams, {
        mechanicId: user.userId,
        companyId: user.profile!.employeeProfile!.companyId,
      })
    } else if (user?.currentScope === UserScope.CompanyOwner) {
      fetchRepairOrderParams.companyId =
        user.profile!.companyProfile!.companyId!
    } else {
      fetchRepairOrderParams.customerId = user.userId
    }

    // Only fetch Archived repairOrders
    fetchRepairOrderParams.status = [RepairOrderStatus.Archived]

    try {
      const data = await fetchRepairOrders(fetchRepairOrderParams)
      setRepairOrders(data)
    } catch (err) {
      // TODO handle properly
      console.error('Something went wrong when fetching repairOrders data', err)
    }
  }

  return (
    <RepairOrderListComponent
      scope={user.currentScope!}
      repairOrders={repairOrders}
      actions={{
        view: {
          isEnabled: true,
          onPress: handleViewDetails,
        },
      }}
    ></RepairOrderListComponent>
  )
}
