import { Platform, StyleSheet } from 'react-native'
import {
  //COLOR_BLUE_DARK,
  COLOR_BUTTON_AQUA_BLUE,
  COLOR_BLUE,
} from '../../../common/constants/colors'

const isWeb = Platform.OS === 'web'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    // flex: 1,
    backgroundColor: '#f5f5f5',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    backgroundColor: '#003366',
    padding: 20,
    alignItems: 'center',
  },
  headerText: {
    fontSize: isWeb ? 32 : 24,
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 10,
    gap: 10,
  },
  registerButton: {
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    paddingVertical: 10,
    borderRadius: 8,
    width: 120,
    alignItems: 'center',
  },
  loginButton: {
    backgroundColor: COLOR_BLUE,
    paddingVertical: 12,
    borderRadius: 8,
    width: 120,
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },

  mainContentContainer: {
    flex: 1, // Ensures the main content occupies remaining space
  },
  mainContent: {
    flex: 1,
    minHeight: 300, // Minimum height for the image
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  tagline: {
    fontSize: isWeb ? 24 : 18,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    marginBottom: 20,
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 4,
  },
  textWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent dark background
    padding: 16,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // For Android shadow
  },
  mission: {
    fontSize: isWeb ? 18 : 14,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 22,
    paddingHorizontal: 20,
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 4,
  },
  footerText: {
    fontSize: 14,
    color: '#fff',
  },
  footer: {
    width: '100%',
    backgroundColor: '#003366',
    padding: 20,
    alignItems: 'center',
  },
  contactInfo: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: 5,
  },
})
